import { differenceInMinutes, parseISO } from 'date-fns';
import { Product } from '../../../common/types/product';
import { Rental } from '../../../common/types/rental';

interface RentalChangeLog {
  oldProduct: Product;
  newProduct: Product;
  boxId: string;
  compartmentId: string;
  compartmentName: string;
  lastRentalOfOldProduct: string;
  firstRentalOfNewProduct: string;
  rentalChangeTime: string;
  timeSinceLastRental?: number;
}

export function getProductChangesInCompartment(
  rentals: Rental[]
): RentalChangeLog[] {
  // Group rentals by compartment
  const compartmentGroups = rentals.reduce((acc, rental) => {
    const compartmentId = rental.compartment.id;
    if (!acc[compartmentId]) {
      acc[compartmentId] = [];
    }
    acc[compartmentId].push(rental);
    return acc;
  }, {} as Record<string, Rental[]>);

  const changes: RentalChangeLog[] = [];

  // For each compartment, detect product changes and calculate time difference
  Object.values(compartmentGroups).forEach((compartmentRentals) => {
    // Sort by rent start time
    compartmentRentals.sort(
      (a, b) =>
        parseISO(a.rentStart).getTime() - parseISO(b.rentStart).getTime()
    );

    // Track the last product and time
    let lastProduct = compartmentRentals[0]?.product;
    let lastRentalTime = parseISO(compartmentRentals[0]?.rentStart);

    for (let i = 1; i < compartmentRentals.length; i++) {
      const currentRental = compartmentRentals[i];
      const currentProduct = currentRental.product;
      const currentRentalTime = parseISO(currentRental.rentStart);

      // Check if the product has changed
      if (currentProduct.id !== lastProduct.id) {
        const timeSinceLastRental = differenceInMinutes(
          currentRentalTime,
          lastRentalTime
        );

        // Log the change
        changes.push({
          oldProduct: lastProduct,
          newProduct: currentProduct,
          boxId: currentRental.box.id,
          compartmentId: currentRental.compartment.id,
          compartmentName:
            currentRental.compartment.name || 'Unnamed Compartment',
          lastRentalOfOldProduct: lastRentalTime.toISOString(),
          firstRentalOfNewProduct: currentRentalTime.toISOString(),
          rentalChangeTime: currentRentalTime.toISOString(),
          timeSinceLastRental,
        });

        // Update last product and time
        lastProduct = currentProduct;
        lastRentalTime = currentRentalTime; // Update this to the first rental of the new product
      } else {
        // Update `lastRentalTime` for the same product
        lastRentalTime = currentRentalTime;
      }
    }
  });

  return changes;
}
