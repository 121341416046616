import * as React from 'react';
import { Box } from '../../webui/Box';
import { Typography } from '../../webui/Typography';

export interface ProductTranslationProps {
  lng: 'da' | 'en' | 'no' | 'sv';
  name: string;
  description: string;
}

export const ProductTranslation: React.FC<ProductTranslationProps> = ({
  description,
  lng,
  name,
}) => (
  <Box mt={2} display={'flex'} alignItems={'flex-start'}>
    <img
      src={`/flags/flag-${lng}.svg`}
      alt={`flag-${lng}`}
      width={20}
      height={20}
    />
    <Box ml={1}>
      <Typography variant={'h5'}>{name}</Typography>
      <Typography>{description}</Typography>
    </Box>
  </Box>
);
