import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '../../webui/Link';
import { Routes } from '../Routes';

interface Props {
  boxId: string;
  compartmentId: string;
  linkText: string;
}

export const CompartmentLink: React.FC<Props> = ({
  boxId,
  compartmentId,
  linkText,
}) => {
  return (
    <Link
      component={RouterLink}
      to={generatePath(Routes.COMPARTMENT, {
        boxId,
        compartmentId,
      })}
    >
      {linkText}
    </Link>
  );
};
