import React from 'react';
import { generatePath } from 'react-router';
import { CompartmentLink } from '../../../common/router_links/CompartmentLink';
import { Routes } from '../../../common/Routes';
import { Compartment } from '../../../common/types/compartment';
import { toDisplayDateTime } from '../../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../../common/utils/resolveBoxDisplayName';
import { resolveProductStatusDisplayName } from '../../../common/utils/resolveProductStatusDisplayName';
import { LoadingSpinnerContainer } from '../../../webui/progress/LoadingSpinnerContainer';
import { SortablePaginatedTable } from '../../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../../webui/table/sortable-paginated-table/TableHeadCell';
import { getNumericalComparator } from '../../../webui/table/sortable-paginated-table/utils';
import { getCurrentRental } from '../../rentals/utils/getCurrentRental';
import { getLatestRental } from '../../rentals/utils/getLatestRental';
import { useBoxById } from '../gql/useBoxById';
import { CurrentStatusData } from './types';

interface CurrentStatusTableProps {
  boxId: string;
}

const createTableHeadCells = (
  boxId: string
): TableHeadCell<CurrentStatusData>[] => [
  {
    id: 'name',
    label: 'Name',
    render: (value) => (
      <CompartmentLink
        boxId={boxId}
        compartmentId={value.id}
        linkText={value.name}
      />
    ),
  },
  {
    id: 'circuitBoardPort',
    label: 'Port nr',
    render: (value) => value['circuitBoardPort'],
  },
  {
    id: 'product',
    label: 'Product',
    render: (value) => value['product'],
  },
  {
    id: 'rentingBy',
    label: 'Currently rented by',
    render: (value) => value['rentingBy'],
  },
  {
    id: 'numberOfRentals',
    label: 'Number of rentals',
    render: (value) => value['numberOfRentals'],
  },
  {
    id: 'latestRental',
    label: 'Latest rental',
    render: (value) => value['latestRental'],
  },
  {
    id: 'productStatus',
    label: 'Product status',
    render: (value) =>
      value['productStatus']
        ? resolveProductStatusDisplayName(value['productStatus'])
        : '',
  },
  {
    id: 'locked',
    label: '',
    render: (value) => <>{value['locked'] ? 'Locked' : ''}</>,
  },
  {
    id: 'private',
    label: '',
    render: (value) => <>{value['private'] ? 'Private' : ''}</>,
  },
];

const createRows = (compartments: Compartment[]): CurrentStatusData[] => {
  return compartments.map((compartment) => {
    const rentalsWithCurrentProduct = compartment.rentals.filter(
      (rental) => rental.product.id === compartment.product?.id
    );
    const latestRental = getLatestRental(rentalsWithCurrentProduct);
    const lastRating = latestRental?.review?.ratingQuestionAnswers[0]?.rating;
    const rentingBy =
      getCurrentRental(rentalsWithCurrentProduct)?.rentBy.email ?? '';

    return {
      id: compartment.id,
      locked: compartment.locked ?? false,
      name: compartment.name || '__no_name__',
      product: compartment.product?.name ?? '__no_product__',
      rentingBy,
      numberOfRentals: rentalsWithCurrentProduct.length,
      latestRental: toDisplayDateTime(latestRental?.rentStart) ?? '',
      productStatus: compartment.product?.productStatus,
      rating: lastRating ?? 0,
      private: compartment.private,
      circuitBoardPort: compartment.circuitBoardPort,
    };
  });
};

const INITIAL_ROWS_PER_PAGE = 14;

export const CurrentStatusTable: React.FC<CurrentStatusTableProps> = ({
  boxId,
}) => {
  const {
    data: box,
    loading,
    error,
  } = useBoxById({
    variables: { id: boxId },
  });

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (!box) {
    return <div>Box-data could not be fetched. Reload page.</div>;
  }

  const title = resolveBoxDisplayName(box);

  return (
    <SortablePaginatedTable
      title={title}
      titleLinkUrl={generatePath(Routes.BOX, { id: boxId })}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'name'}
      rows={createRows(box.compartments || [])}
      tableHeadCells={createTableHeadCells(boxId)}
      getComparator={getNumericalComparator}
    />
  );
};
