import React, { useState } from 'react';
import {
  generatePath,
  Link as RouterLink,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import { BoxLink } from '../../common/router_links/BoxLink';
import { Routes } from '../../common/Routes';
import { toDisplayDateTime, toDisplayTime } from '../../common/utils/dateUtils';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { CompartmentPage } from '../compartment/CompartmentPage';
import { BoxColor } from './BoxColor';
import { BoxPageBatteryReadings } from './BoxPageBatteryReadings';
import { CurrentStatusTable } from './current-status/CurrentStatusTable';
import { EditBoxDrawer } from './edit/EditBoxDrawer';
import { EditCompartmentsDrawer } from './edit/EditCompartmentsDrawer';
import { useBoxById } from './gql/useBoxById';
import { LocationTranslation } from './LocationTranslation';
import { BoxMap } from './map/BoxMap';
import { ProductChange } from './ProductChange';
import { RentalHistoryTable } from './rental-history-table/RentalHistoryTable';
import { useTranslatedLocation } from './utils/useTranslatedLocation';

interface BoxPageProps {}

const getLocationDisplayText = (
  locationName: string | undefined,
  locationDescription: string | undefined
): string => {
  if (locationName && locationDescription) {
    return locationName + ' - ' + locationDescription;
  }
  if (locationName) {
    return locationName;
  }
  if (locationDescription) {
    return locationDescription;
  }
  return '';
};

export const BoxPage: React.FC<BoxPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: box, loading, error } = useBoxById({ variables: { id } });
  const [openEditBoxDrawer, setOpenEditBoxDrawer] = useState(false);
  const { tLocationDescription, tLocationName } = useTranslatedLocation();
  const onCloseEditBox = () => {
    setOpenEditBoxDrawer(false);
  };

  const onOpenEditBox = () => {
    setOpenEditBoxDrawer(true);
  };

  const [openEditCompartmentsDrawer, setOpenEditCompartmentsDrawer] =
    useState(false);
  const onCloseEditCompartments = () => {
    setOpenEditCompartmentsDrawer(false);
  };

  const onOpenEditCompartments = () => {
    setOpenEditCompartmentsDrawer(true);
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  if (!box) {
    return <div>Could not find box information.</div>;
  }

  return (
    <Container>
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            <BoxLink boxId={id} linkText={box.name} />
            {!box.active && (
              <Box mt={2}>
                <Typography>This box is not active</Typography>
              </Box>
            )}
            {box.deprecated && (
              <Box mt={2}>
                <Typography>
                  This box is deprecated and not for use anywhere
                </Typography>
              </Box>
            )}
            {box.location && (
              <Box mt={2}>
                <Typography>
                  {getLocationDisplayText(
                    box.location.name,
                    box.location.description
                  )}
                </Typography>
                <LocationTranslation
                  lng={'en'}
                  text={getLocationDisplayText(
                    tLocationName(box, 'en'),
                    tLocationDescription(box, 'en')
                  )}
                />
                <LocationTranslation
                  lng={'sv'}
                  text={getLocationDisplayText(
                    tLocationName(box, 'sv'),
                    tLocationDescription(box, 'sv')
                  )}
                />
                <LocationTranslation
                  lng={'no'}
                  text={getLocationDisplayText(
                    tLocationName(box, 'no'),
                    tLocationDescription(box, 'no')
                  )}
                />
                <LocationTranslation
                  lng={'da'}
                  text={getLocationDisplayText(
                    tLocationName(box, 'da'),
                    tLocationDescription(box, 'da')
                  )}
                />
              </Box>
            )}
            <Box mt={2}>
              <Typography>
                {toDisplayTime(box.openStartTime)} -{' '}
                {toDisplayTime(box.openEndTime)}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography>Price weight: {box.priceWeight.name}</Typography>
            </Box>
            <Box display={'flex'} mt={2}>
              <Typography>Color:</Typography>
              <Box ml={1} display={'flex'}>
                <BoxColor color={box.color} />
              </Box>
            </Box>
            {box.sponsor && (
              <Box mt={2}>
                <Typography>
                  Sponsored by:{' '}
                  <Link
                    component={RouterLink}
                    to={generatePath(Routes.SPONSOR, { id: box.sponsor.id })}
                  >
                    {box.sponsor.name}
                  </Link>
                </Typography>
              </Box>
            )}
            {box.initialActivationTime && (
              <Box mt={2}>
                <Typography>
                  Was initially activated:{' '}
                  {toDisplayDateTime(box.initialActivationTime)}
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            <Button onClick={onOpenEditBox}>Edit</Button>
            <Box m={2} />
            <Button onClick={onOpenEditCompartments}>Edit compartments</Button>
          </Box>
        </Box>
      </Paper>
      <Box mt={2}>
        <Paper>
          <BoxMap
            boxMarkers={
              box.location.coordinates
                ? [
                    {
                      active: box.active,
                      color: box.color,
                      coordinates: box.location.coordinates,
                      id: box.id,
                      title: box.name,
                    },
                  ]
                : []
            }
          />
        </Paper>
      </Box>
      <Box mt={2}>
        <Switch>
          <Route path={Routes.COMPARTMENT}>
            <CompartmentPage />
          </Route>
          <Route path="/">
            <Box my={2}>
              <Paper>
                <CurrentStatusTable boxId={box.id} />
              </Paper>
            </Box>
            <Box my={2}>
              <Paper>
                <RentalHistoryTable boxId={box.id} />
              </Paper>
            </Box>
            <Box my={2}>
              <Paper>
                <BoxPageBatteryReadings boxId={box.id} />
              </Paper>
            </Box>
            <Box my={2}>
              <Paper>
                <ProductChange boxId={box.id} />
              </Paper>
            </Box>
          </Route>
        </Switch>
      </Box>
      <EditBoxDrawer
        box={box}
        open={openEditBoxDrawer}
        onClose={onCloseEditBox}
      />
      <EditCompartmentsDrawer
        box={box}
        open={openEditCompartmentsDrawer}
        onClose={onCloseEditCompartments}
      />
    </Container>
  );
};
