import { getAuth } from 'firebase/auth';
import i18n, { LanguageDetectorModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationSV from './locales/sv/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  sv: {
    translation: translationSV,
  },
};

export const initI18N = async () => {
  const RNLanguageDetector: LanguageDetectorModule = {
    type: 'languageDetector',
    init: () => {},
    detect: () => 'sv',
    cacheUserLanguage: () => {},
  };

  i18n
    .use(RNLanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      // lng: 'sv', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      supportedLngs: ['en-US', 'da', 'en', 'no', 'sv'],
      fallbackLng: ['sv'],
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    }); // passes i18n down to react-i18next

  const token = await getAuth().currentUser?.getIdToken();

  for (const language of ['da', 'en', 'no', 'sv']) {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/translations`, {
      headers: {
        'accept-language': language,
        authorization: `Bearer ${token}`,
      },
    })
      .then((result) => result.json())
      .then((json) => {
        if (json) {
          try {
            i18n.addResourceBundle(
              language,
              'translation',
              JSON.parse(json),
              true,
              true
            );
          } catch (e) {
            console.log('Error while parsing translation', e);
          }
        }
      })
      .catch(() => {});
  }
};
