import { ApolloError } from '@apollo/client';
import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { CompartmentLink } from '../../common/router_links/CompartmentLink';
import { Routes } from '../../common/Routes';
import { Rental } from '../../common/types/rental';
import { toDisplayDateTime } from '../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { resolveProductStatusDisplayName } from '../../common/utils/resolveProductStatusDisplayName';
import { Box } from '../../webui/Box';
import { ShoppingCartIcon } from '../../webui/icons/ShoppingCartIcon';
import { Link } from '../../webui/Link';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Rating } from '../../webui/Rating';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import { CopyRentalButton } from './CopyRentalButton';
import { PostBoxActionButton } from './PostBoxActionButton';
import { AllRentalData } from './types';
import { rentalComparator } from './utils/allRentalDataUtils';
import { resolvePaymentStatus } from './utils/resolvePaymentStatus';

interface Props {
  error?: ApolloError;
  hasReachedPenultimatePage?: () => void;
  loading?: boolean;
  rentals: Rental[];
  searchEnabled?: boolean;
  title?: string;
}

const createTableHeadCells: TableHeadCell<AllRentalData>[] = [
  {
    id: 'copyRental',
    label: '',
    render: (value) => <CopyRentalButton rental={value} />,
  },
  {
    id: 'postBoxAction',
    label: '',
    render: (value) => <PostBoxActionButton rental={value} />,
  },
  {
    id: 'id',
    label: 'Id',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.RENTAL, {
          id: row.id,
        })}
      >
        {row.id}
      </Link>
    ),
    maxWidth: 50,
  },
  {
    id: 'box',
    label: 'Box',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.BOX, {
          id: row['box'].id,
        })}
      >
        {resolveBoxDisplayName(row['box'])}
      </Link>
    ),
  },
  {
    id: 'compartment',
    label: 'Compartment',
    render: (row) => (
      <CompartmentLink
        boxId={row.box.id}
        compartmentId={row.compartment.id}
        linkText={row.compartment.name ?? ''}
      />
    ),
  },
  {
    id: 'product',
    label: 'Product',
    render: (value) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.PRODUCT, {
          id: value['product'].id,
        })}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          {value['product'].name}
          {value['product'].rentalType === 'buy' && (
            <Box ml={1} display={'flex'} alignItems={'center'}>
              <ShoppingCartIcon />
            </Box>
          )}
        </Box>
      </Link>
    ),
  },
  {
    id: 'rentBy',
    label: 'Rented by',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.USER, {
          id: row['rentBy'].id,
        })}
      >
        {row['rentBy'].email || 'Anonymized user'}
      </Link>
    ),
  },
  {
    id: 'rentTime',
    label: 'How long?',
    render: (value) => value['rentTime'],
  },
  {
    id: 'rentStart',
    label: 'Started when?',
    render: (value) => toDisplayDateTime(value['rentStart']),
  },
  {
    id: 'rentEnd',
    label: 'Ended when?',
    render: (value) => toDisplayDateTime(value['rentEnd']),
  },
  {
    id: 'productStatus',
    label: 'Product status',
    render: (value) =>
      value['productStatus']
        ? resolveProductStatusDisplayName(value['productStatus'])
        : '',
  },
  {
    id: 'productStatusText',
    label: 'Product status text',
    render: (value) => value['productStatusText'],
  },
  {
    id: 'aborted',
    label: 'Aborted?',
    render: (value) => (value['aborted'] ? 'YES' : ''),
  },
  {
    id: 'paymentChargeInformation',
    label: 'Payment status',
    render: (value) =>
      resolvePaymentStatus(value['paymentChargeInformation']) ?? '',
  },
  {
    id: 'numberOfSuccessfulOpenings',
    label: 'Successful openings',
    render: (value) => value['numberOfSuccessfulOpenings'],
  },
  {
    id: 'numberOfFailedOpenings',
    label: 'Failed openings',
    render: (value) => value['numberOfFailedOpenings'],
  },
  {
    id: 'reviewRating',
    label: 'Rating',
    render: (value) => (
      <Rating readOnly={true} value={value.review?.rating ?? 0} />
    ),
  },
  {
    id: 'reviewFreetext',
    label: 'Feedback',
    render: (value) => value.reviewFreetext,
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (rentals: Rental[]): AllRentalData[] =>
  rentals.map((rental) => {
    return {
      id: rental.id,
      aborted: rental.aborted,
      box: rental.box,
      compartment: rental.compartment,
      copyRental: true,
      postBoxAction: true,
      maxRentTimeInMinutes: rental.maxRentTimeInMinutes,
      paymentChargeInformation: rental.paymentChargeInformation,
      product: rental.product,
      productStatus: rental.productStatus,
      productStatusText: rental.productStatusText,
      numberOfFailedOpenings: rental.numberOfFailedOpenings,
      numberOfSuccessfulOpenings: rental.numberOfSuccessfulOpenings,
      rentBy: rental.rentBy,
      rentStartBy: rental.rentStartBy,
      rentEndBy: rental.rentEndBy,
      rentStart: rental.rentStart,
      rentEnd: rental.rentEnd,
      rentTime: rental.rentTime,
      review: rental.review,
      reviewRating: rental.review?.rating,
      reviewFreetext: rental.review?.freeText,
    };
  });

export const RentalsTable: React.FC<Props> = ({
  error,
  hasReachedPenultimatePage,
  loading,
  rentals,
  searchEnabled,
  title = 'All rentals',
}) => {
  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <SortablePaginatedTable
      getComparator={rentalComparator}
      hasReachedPenultimatePage={hasReachedPenultimatePage}
      title={title}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'rentEnd'}
      initialOrder={'desc'}
      rows={createRows(rentals)}
      searchEnabled={searchEnabled}
      tableHeadCells={createTableHeadCells}
    />
  );
};
